<template>
  <v-container grid-list-xs text-xs-start>
    <v-form
      v-model="valid"
      v-if="organizedFields.write.length > 0"
    >
      <v-layout
          v-for="(cardRow, cr) in organizedFields.write"
          :key="cr"
          class="mt-6 mx-6"
          align-start justify-center row>
        <v-flex
          v-for="(cardCol, cc) in cardRow"
          :key="`${cr}-${cc}`"
          :class="cardCol.cw">
          <v-card class="pa-6">
            <v-flex
              xs12
              v-if="cardCol.title"
            >
              <h3>{{ $t(cardCol.title) }}</h3>
            </v-flex>
            <v-flex
              v-for="(fieldCol, fc) in cardCol"
              :key="`${cr}-${cc}-${fc}`"
              :class="fieldCol.fw">
                <Fields
                  :group-id="objectId"
                  :fields="fieldCol"
                  v-on:change="change($event, `${cr}-${cc}-${fc}`)"
                  :readonly="readonly"
                  :extLoading="loading"
                />
            </v-flex>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout align-start justify-center row class="mt-2 mx-6" v-if="!readonly">
        <v-flex xs12 class="text-right">
          <v-btn
            v-on:click="cancel"
            class="white--text mr-2"
            color="secondary"
          >
            {{ $t('crud.cancel') }}
          </v-btn>
          <v-btn
            :disabled="!edited || !valid"
            :loading="loading"
            v-on:click="save"
            class="white--text"
            color="primary"
          >
            {{
              $route.params.objectId !== 'new' ?
              $t('crud.save'):
              $t('crud.create')
            }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
    <template
      v-if="organizedFields.read.length > 0">
      <v-layout
        class="mt-6 mx-6"
        v-for="(cardRow, cr) in organizedFields.read"
        :key="cr"
        align-start justify-center row>
        <v-flex
          v-for="(cardCol, cc) in cardRow"
          :key="`${cr}-${cc}`"
          :class="cardCol.cw">
          <v-card class="pa-6">
            <v-flex
              xs12
              v-if="cardCol.title"
            >
              <h3>{{ $t(cardCol.title) }}</h3>
            </v-flex>
            <v-flex
              v-for="(fieldCol, fc) in cardCol"
              :key="`${cr}-${cc}-${fc}`"
              :class="fieldCol.fw">
                <Fields
                  :group-id="objectId"
                  :fields="fieldCol"
                  :readonly="true"
                />
            </v-flex>
          </v-card>
        </v-flex>
      </v-layout>
    </template>
    <slot></slot>
  </v-container>
</template>

<script>
import dataAccess from '../../misc/dataAccess';
import _ from '../../misc/lodash';

export default {
  name: 'Item',
  props: {
    componentData: {
      type: Object,
    },
    overrideObjectId: {
      type: String,
      default: '',
    },
    overrideMetaInfo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      organizedFields: {
        write: [],
        read: [],
      },
      currentData: {},
      valid: false,
      loading: false,
      name: null,
      onlySwitch: false,
    };
  },
  computed: {
    currentPage() {
      return (this.componentData && this.componentData.options)
        || this.$store.state.settings.currentPage;
    },
    options() {
      const { options } = this.currentPage;
      return Array.isArray(options) ? options[0] : options;
    },
    readonly() {
      return this.options.permissions.indexOf('update') === -1;
    },
    object() {
      return this.options.object;
    },
    alias() {
      return this.options.alias || this.options.object;
    },
    plugin() {
      return this.options.plugin;
    },
    objectId() {
      return (this.componentData && this.componentData.value)
        || this.$route.params.objectId
        || this.overrideObjectId;
    },
    readStatus() {
      return this.options.readStatus;
    },
    edited() {
      return this.organizedFields.write
        .some((cardRow) => (cardRow
          .some((cardCol) => (cardCol
            .some((colFields) => (colFields
              .some((field) => (field.edited))))))));
    },
    colClass() {
      return 'xs12';
    },
  },
  watch: {
    async currentPage() {
      await this.loadItem();
    },
    '$route.path': async function w() {
      await this.loadItem();
    },
    currentData() {
      this.$emit('current-data', this.currentData);
    },
  },
  methods: {
    async computeName() {
      let name = this.objectId;

      if (this.options.name) {
        try {
          name = await dataAccess.format(
            dataAccess.get(this.currentData, this.options.name.path),
            this.options.name.format,
          );
        } catch (e) {
          name = this.objectId;
        }
      }
      this.name = name;
    },
    async computeFields() {
      this.currentData = this.$store.getters['crud/GET_DATA_BY_ID'](this.alias, this.objectId) || {};
      await this.computeName();

      let switchFID;
      if (this.onlySwitch) {
        this.organizedFields.write
          .forEach((cardRow) => (cardRow
            .forEach((cardCol) => (cardCol
              .forEach((colFields) => (colFields
                .forEach((field) => {
                  if (field.edited) {
                    switchFID = field.fid;
                    this.currentData = _.set(this.currentData, field.path, field.value);
                  }
                })))))));
      }

      let rawFields;
      if (!Array.isArray(this.options.fields)) {
        rawFields = this.options.fields;
      } else {
        if (this.options.fields.length === 1 || !this.options.switch) {
          ([rawFields] = this.options.fields);
        }

        let switchValue;
        try {
          switchValue = await dataAccess.format(
            dataAccess.get(this.currentData, this.options.switch.path),
            this.options.switch.format,
          );
        // eslint-disable-next-line no-empty
        } catch (e) {}

        if (switchValue) {
          const fields = this.options.fields
            .find((fieldGroup) => (fieldGroup.switch === switchValue));

          if (fields) {
            rawFields = fields;
          }
        }

        this.onlySwitch = !rawFields;
        if (!rawFields) {
          this.onlySwitch = true;
          rawFields = {
            write: [
              this.options.switch,
            ],
            read: [],
          };
        }
      }

      if (!this.onlySwitch) {
        rawFields = JSON.parse(JSON.stringify(rawFields));
        if (!rawFields.write) {
          rawFields.write = [];
        }

        if (!rawFields.read) {
          rawFields.read = [];
        }
      }

      let fields = rawFields.write
        .map((edition) => {
          let value = dataAccess.get(this.currentData, edition.path);

          if (this.objectId === 'new' && edition.default && !value && value !== false) {
            value = edition.default;
          }

          if (edition.component === 'autocompleteField' || edition.component === 'selectField') {
            if (typeof value !== 'undefined') {
              if (!Array.isArray(value) && edition.multiple) {
                value = [value];
              }
            } else {
              value = [];
            }
          }

          return {
            value,
            initial: value,
            edited: switchFID === edition.fid
              || (this.objectId === 'new' && value === edition.default),
            ...edition,
          };
        });

      for (let index = 0; index < fields.length; index += 1) {
        if (fields[index].format && fields[index].readonly) {
          // eslint-disable-next-line no-await-in-loop
          fields[index].value = await dataAccess.format(
            fields[index].value,
            fields[index].format,
          );
        }
      }

      fields.sort((fA, fB) => {
        const coordinatesA = fA.coordinates || {
          cr: 0,
          cc: 0,
          cw: this.colClass,
          fc: 0,
          fw: 'xs12',
        };
        const coordinatesB = fB.coordinates || {
          cr: 0,
          cc: 0,
          cw: this.colClass,
          fc: 0,
          fw: 'xs12',
        };

        if (coordinatesA.cr < coordinatesB.cr) {
          return -1;
        }
        if (coordinatesA.cr > coordinatesB.cr) {
          return 1;
        }
        if (coordinatesA.cc < coordinatesB.cc) {
          return -1;
        }
        if (coordinatesA.cc > coordinatesB.cc) {
          return 1;
        }
        if (coordinatesA.fc < coordinatesB.fc) {
          return -1;
        }
        if (coordinatesA.fc > coordinatesB.fc) {
          return 1;
        }
        if (!coordinatesA.fr) {
          return 1;
        }
        if (!coordinatesB.fr) {
          return -1;
        }
        if (coordinatesA.fr < coordinatesB.fr) {
          return -1;
        }
        if (coordinatesA.fr > coordinatesB.fr) {
          return 1;
        }

        return 0;
      });

      const writeFields = [];

      fields.forEach((field) => {
        const coordinates = field.coordinates || {
          cr: 0,
          cc: 0,
          cw: this.colClass,
          fc: 0,
          fw: 'xs12',
        };

        if (!writeFields[coordinates.cr]) {
          writeFields[coordinates.cr] = [];
        }
        if (!writeFields[coordinates.cr][coordinates.cc]) {
          writeFields[coordinates.cr][coordinates.cc] = [];
          writeFields[coordinates.cr][coordinates.cc].cw = coordinates.cw || 'xs12';
          writeFields[coordinates.cr][coordinates.cc].title = coordinates.title || '';
        }
        if (!writeFields[coordinates.cr][coordinates.cc][coordinates.fc]) {
          writeFields[coordinates.cr][coordinates.cc][coordinates.fc] = [];
          writeFields[coordinates.cr][coordinates.cc][coordinates.fc]
            .fw = coordinates.fw || 'xs12';
        }

        if (typeof coordinates.fr === 'undefined') {
          coordinates.fr = writeFields[coordinates.cr][coordinates.cc][coordinates.fc].length;
        }

        writeFields[coordinates.cr][coordinates.cc][coordinates.fc][coordinates.fr] = field;
      });

      fields = rawFields.read
        .map((read) => {
          if (read.path) {
            let value = dataAccess.get(this.currentData, read.path);

            if (!value && value !== false) {
              return false;
            }

            if (read.component === 'autocompleteField' || read.component === 'selectField') {
              if (typeof value !== 'undefined') {
                if (!Array.isArray(value) && read.multiple) {
                  value = [value];
                }
              } else {
                value = [];
              }
            }

            return {
              value, initial: value, edited: false, ...read,
            };
          }

          if (read.component === 'layoutField') {
            const value = this.currentData;

            return {
              value, edited: false, ...read,
            };
          }
          return read;
        }).filter((read) => (read !== false));

      for (let index = 0; index < fields.length; index += 1) {
        if (fields[index].format) {
          // eslint-disable-next-line no-await-in-loop
          fields[index].value = await dataAccess.format(
            fields[index].value,
            fields[index].format,
          );
        }
      }

      fields.sort((fA, fB) => {
        const coordinatesA = fA.coordinates || {
          cr: 0,
          cc: 0,
          cw: this.colClass,
          fc: 0,
          fw: 'xs12',
        };
        const coordinatesB = fB.coordinates || {
          cr: 0,
          cc: 0,
          cw: this.colClass,
          fc: 0,
          fw: 'xs12',
        };

        if (coordinatesA.cr < coordinatesB.cr) {
          return -1;
        }
        if (coordinatesA.cr > coordinatesB.cr) {
          return 1;
        }
        if (coordinatesA.cc < coordinatesB.cc) {
          return -1;
        }
        if (coordinatesA.cc > coordinatesB.cc) {
          return 1;
        }
        if (coordinatesA.fc < coordinatesB.fc) {
          return -1;
        }
        if (coordinatesA.fc > coordinatesB.fc) {
          return 1;
        }
        if (!coordinatesA.fr) {
          return 1;
        }
        if (!coordinatesB.fr) {
          return -1;
        }
        if (coordinatesA.fr < coordinatesB.fr) {
          return -1;
        }
        if (coordinatesA.fr > coordinatesB.fr) {
          return 1;
        }

        return 0;
      });

      const readFields = [];

      fields.forEach((field) => {
        const coordinates = field.coordinates || {
          cr: 0,
          cc: 0,
          cw: this.colClass,
          fc: 0,
          fw: 'xs12',
        };

        if (!readFields[coordinates.cr]) {
          readFields[coordinates.cr] = [];
        }
        if (!readFields[coordinates.cr][coordinates.cc]) {
          readFields[coordinates.cr][coordinates.cc] = [];
          readFields[coordinates.cr][coordinates.cc].cw = coordinates.cw || 'xs12';
        }
        if (!readFields[coordinates.cr][coordinates.cc][coordinates.fc]) {
          readFields[coordinates.cr][coordinates.cc][coordinates.fc] = [];
          readFields[coordinates.cr][coordinates.cc][coordinates.fc]
            .fw = coordinates.fw || 'xs12';
        }

        if (typeof coordinates.fr === 'undefined') {
          coordinates.fr = readFields[coordinates.cr][coordinates.cc][coordinates.fc].length;
        }

        readFields[coordinates.cr][coordinates.cc][coordinates.fc][coordinates.fr] = field;
      });

      this.organizedFields = {
        write: writeFields,
        read: readFields,
      };
    },
    async cancel() {
      if (!this.componentData) {
        this.$router.go(-1);
      }
      await this.computeFields();
    },
    async change(fields, path) {
      _.set(this.organizedFields, path, fields);
      if (this.onlySwitch) {
        await this.computeFields();
      }
    },
    async save() {
      let currentData = {};
      this.organizedFields.write
        .forEach((cardRow) => (cardRow
          .forEach((cardCol) => (cardCol
            .forEach((colFields) => (colFields
              .forEach((field) => {
                if (field.edited) {
                  // TODO check for addition
                  currentData = _.set(
                    currentData,
                    field.writePath || field.path,
                    field.value,
                    // !field.addition, // legacy function
                  );
                }
              })))))));
      this.currentData = currentData;
      if (!_.isEmpty(this.currentData)) {
        this.loading = true;
        this.$nextTick(async () => {
          if (this.objectId === 'new') {
            const result = await this.$store
              .dispatch('crud/CREATE', {
                object: this.object,
                alias: this.alias,
                body: this.currentData,
                params: this.options.queryParams,
              });
            if (result !== false) {
              this.$nextTick(() => {
                setTimeout(() => {
                  if (this.options.itemRoute) {
                    this.$router.push(`${this.options.itemRoute}/${result.id}`);
                  } else {
                    this.$router.push(`/item/${this.object}/${result.id}`);
                  }
                }, 1000);
              });
            }
          } else {
            await this.$store
              .dispatch('crud/UPDATE', {
                object: this.object,
                alias: this.alias,
                objectId: this.objectId,
                body: this.currentData,
                params: this.options.queryParams,
              });
            // TODO Dirt fix need found better solution, maybe update data in store and don't search
            // * Need delay before re computed fields due to delay of kuzzle to update data
            this.$nextTick(() => {
              setTimeout(() => {
                this.computeFields();
              }, 1000);
            });
          }
          setTimeout(() => {
            // we put a timeout in order for the event to be visible
            this.loading = false;
          }, 1000);
        });
      }
    },
    async loadItem() {
      if (this.objectId !== 'new') {
        const object = await this.$store
          .dispatch('crud/READ', {
            object: this.object,
            alias: this.alias,
            objectId: this.objectId,
            params: this.options.queryParams,
          });

        if (this.readStatus) {
          await this.$store
            .dispatch('crud/READ_STATUS', {
              body: {
                customer: object._source.customer,
                application: object._source.application,
                plugin: this.plugin,
                object: this.object,
                alias: this.alias,
                objectId: object.id,
                read: true,
              },
            });
        }

        if (!object && !this.componentData) {
          this.$router.go(-1);
        }
      }
      await this.computeFields();
    },
  },
  async mounted() {
    await this.loadItem();
  },
  metaInfo() {
    this.$store.commit('settings/SET_CURRENT_TITLE', '');
    if (!this.componentData) {
      let title = '';
      if (this.objectId !== 'new' && !this.overrideMetaInfo && this.name) {
        title = `: ${this.name}`;
      } else if (this.objectId !== 'new' && this.overrideMetaInfo) {
        title = `${this.overrideMetaInfo}`;
      } else if (this.objectId === 'new') {
        title = `: ${this.$t('misc.create')}`;
      }
      this.$store.commit('settings/SET_CURRENT_TITLE', title.trim());
      return {
        title: title.trim(),
      };
    }
    return null;
  },
  beforeDestroy() {
    this.$store.commit('settings/SET_CURRENT_TITLE', '');
  },
};
</script>
